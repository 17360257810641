<template>
  <div>

    <j-form-modal
      v-model="open"
      title="Writable Button"
      ref="subItemFormHandler"
      @create="add()"
      @edit="edit()"
      @delete="del()"
      @cancel="exitDialog"
      :formMode="formode"
      :resetable="resetable()"
      :opened.sync="open"
    >
      <v-container>
        <v-layout wrap>
          <v-flex xs12 class="disabled">
            <v-text-field
              v-model="selectedItem.buttonCode"
              label="Button Code"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 class="wrap__option_group">
            <span class="label">Sub-Item</span>
            <div class="option_group">

              <label for="0">
                <input 
                  v-model="selectedItem.subItem"
                  type="radio" 
                  name="subItem" 
                  id="0" 
                  value=0
                >
                None
              </label>
              <label :for="option.idx" v-for="option in subItemOptions" :key="option.index">
                <input 
                  v-model="selectedItem.subItem"
                  type="radio" 
                  name="subItem" 
                  :id="option.idx" 
                  :value="option.idx"
                >
                {{ option.subItemName }}
              </label>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="selectedItem.reportName"
              label="Report Name"
              placeholder="Input/Edit name of report"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="selectedItem.desc"
              name="desc"
              label="Description"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 class="wrap__multiselect">
            <v-select
              v-model="accessGroup"
              item-text="name"
              return-object
              label="Accessible Groups"
              multiple
              placeholder="Select Groups to assign"
              :items="accessGroupOptions"
              :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
            ></v-select>
          </v-flex>
          <v-flex xs6 class="wrap__multiselect">
            <v-select
              v-model="accessUser"
              item-text="userName"
              return-object
              label="Accessible Members"
              multiple
              placeholder="Select members to assign"
              :items="accessUserOptions"
              :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-container>
    </j-form-modal>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </div>
</template>

<script>

import __C from '@/primitives/_constant_'
import { AdminService, SystemService } from "@/services"
import JFormModal       from '@/components/floating/JFormModal'

export default {
  name: "j-writable-box",
  components: {
    JFormModal,
    // JAlertExtended
  },
  props: {
    open: { type: Boolean, default: false },
    modal: { type: Boolean, default: true },
    formode: { type: String, default: __C.FORM.EDIT_MODE_MOD },
    receivedItem: { type: Object, default: () => ({}) },
    comparedItem: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      systemService: null,
      adminService: null,
      phaseOptions: [],
      groupOptions: [],
      itemOptions: [],
      subItemOptions: [],
      accessUserOptions: [],
      accessUser: [],
      accessGroupOptions: [],
      accessGroup: [],
      dialog: false,
      valid: false,
      selected: [],
      selectedIndex: -3,
      selectedItem: [],
      prevSelectedItem: this.comparedItem,
      msgOpen: false,
      msgInfo: {
        type: "",
        title: "",
        titleDescription: "",
        message: "",
        button: [true, false, true],
        buttonText: ["Yes", "No", "Cancel"]
      },
      yes: () => {}
    }
  },
  created() {
    this.adminService = new AdminService();
    this.systemService = new SystemService();
  },
  mounted() {
    this.systemService.getNavPhase(res => {
      if (!res) this.phaseOptions = [];
      else this.phaseOptions = res;
      this.systemService.getCodeIndex("PERMISSION", res => {
        if (!res) this.accessGroupOptions = [];
        else this.accessGroupOptions = res;
        this.adminService.getMembers("ALL", res => {
          if (!res) this.accessUserOptions = [];
          this.accessUserOptions = res;
          this.systemService.getWButton(
            this.setWButton,
            this.codePropagated.parentCode,
            this.codePropagated.code
          );
        });
      });
    });
  },
  watch: {
    accessUser(val) {
      let memberIDs = [];
      val.forEach(m => {
        memberIDs.push(m.id);
      });
      this.selectedItem.accessUser = memberIDs.join(",");
    },
    accessGroup(val) {
      let groupCodes = [];
      val.forEach(m => {
        groupCodes.push(m.code);
      });
      this.selectedItem.accessGroup = groupCodes.join(",");
    },
    receivedItem(val) {
      this.selectedItem = val;
    },
    isOpen(val) {
      this.dialog = val;
      if (
        !!this.selectedItem.accessUser &&
        this.selectedItem.accessUser !== ""
      ) {
        var accessUserStr = this.selectedItem.accessUser;
        this.accessUser = [];
        accessUserStr.split(",").forEach(id => {
          let member = this.accessUserOptions.find(o => o.id == id);
          if (!!member) this.accessUser.push(member);
        });
      }
      if (
        !!this.selectedItem.accessGroup &&
        this.selectedItem.accessGroup !== ""
      ) {
        this.accessGroup = [];
        this.selectedItem.accessGroup.split(",").forEach(code => {
          let group = this.accessGroupOptions.find(o => o.code == code);
          if (!!group) this.accessGroup.push(group);
        });
      }
      return val;
    },
    dialog(val) {}
  },
  computed: {
    isOpen() {
      if(!!this.selectedItem.phaseCode && !!this.selectedItem.itemCode) {
        this.systemService.getNavSubItem(
          res => {
            if(!res) this.subItemOptions = []
            else this.subItemOptions = res
          },
          this.selectedItem.phaseCode,
          this.selectedItem.itemCode
        )
      }
      var opend = this.open;
      return opend;
    },
    codePropagated: {
      get() {
        return this.$store.state.sysenv.codePropagated;
      },
      set(value) {
        this.$store.commit("sysenv/codePropagate", value);
      }
    },
    // ...mapGetters({
    //   comparedItem: "compared/getComparedItem"
    // })
  },
  methods: {
    exitDialog(status) {
      this.$emit("changed", status);
    },
    phaseChanged(phaseCode) {
      this.systemService.getNavGroup(phaseCode, res => {
        if (!res) this.groupOptions = [];
        else this.groupOptions = res;
      });
    },
    groupChanged(groupCode) {
      this.systemService.getNavItem(
        res => {
          if (!res) this.itemOptions = [];
          else this.itemOptions = res;
        },
        this.selectedItem.phaseCode,
        groupCode
      );
    },
    itemChanged(itemCode) {
      if(!!this.selectedItem.phaseCode && !!this.selectedItem.itemCode) {
        this.systemService.getNavSubItem(
          res => {
            if(!res) this.subItemOptions = []
            else this.subItemOptions = res
          },
          this.selectedItem.phaseCode,
          this.selectedItem.itemCode
        )
      }
    },
    setWButton(res) {
      if (!res) {
        res = [];
      }
      this.wButton = res;
      if (this.selectedIndex < -1) {
        ++this.selectedIndex;
      } else if (this.selectedIndex == -1) {
        this.setEdit(
          this.wButton.findIndex(
            x => x.buttonCode === this.selectedItem.buttonCode
          )
        )
      } else {
        if (
          this.wButton.findIndex(
            x => x.buttonCode === this.selectedItem.buttonCode
          ) < 0
        ) {
          if (this.wButton.length > 0) {
            if (this.wButton.length >= this.selectedIndex + 1) {
              //
            } else {
              this.selectedIndex = this.wButton.length - 1;
            }
            this.setEdit(this.selectedIndex);
          } else {
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex);
        }
      }
    },
    customFilter(items, search, filter) {
      search = search.toString().toLowerCase();

      let filtered = items.filter(i =>
        Object.keys(i).some(j => filter(i[j], search))
      );

      // Renumbering for the action of Drag & Drop and search filtering.
      let reNumbering = 0;
      filtered.forEach(_item => {
        this.wButton.find(
          _item_ => _item_.buttonCode == _item.buttonCode
        ).no = ++reNumbering;
      });

      return filtered;
    },
    add() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.putWButton(this.selectedItem, res => {
          this.systemService.getWButton(
            this.setWButton,
            this.codePropagated.parentCode,
            this.codePropagated.code
          )
        })
        this.yes = () => {};
      }
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save new Writable Button?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    edit() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.updWButton(this.selectedItem, res => {});
        this.exitDialog("UPDATE");
        this.yes = () => {};
      }
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save this changes?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.delWButton(this.selectedItem.idx, res => {});
        this.yes = () => {};
        this.exitDialog("DELETE");
      }
      this.msgInfo.type = "WARN";
      this.msgInfo.title = "Action Approval";
      this.msgInfo.titleDescription = "Important Notification";
      this.msgInfo.message = "Do you want to delete selected Writable Button?";
      this.msgInfo.buttonText[0] = "Delete";
      this.msgOpen = true;
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.wButton.filter(e => {
          return (
            (e.itemName || "").toLowerCase().indexOf((v || "").toLowerCase()) >
            -1
          )
        })
        this.loading = false;
      }, 500)
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    propStatus(props) {
      this.selectedIndex = this.wButton.indexOf(props.item);
      this.selectedItem = Object.assign({}, props.item);

      if (this.receivedItem.length > 0) {
        if (this.receivedItem[0].buttonCode === props.item.buttonCode) {
          this.modOpen = true;
          return;
        } else {
          let _selected = props.item;
          this.selected = [_selected];
        }
      } else {
        props.selected = !props.selected;
      }
      this.receivedItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
      this.modOpen = true;

      this.accessUser = [];
      this.selectedItem.accessUser.split(",").forEach(id => {
        let member = this.accessUserOptions.find(o => o.id == id);
        if (!!member) this.accessUser.push(member);
      });

      this.accessGroup = [];
      this.selectedItem.accessGroup.split(",").forEach(code => {
        let group = this.accessGroupOptions.find(o => o.code == code);
        if (!!group) this.accessGroup.push(group);
      });

      this.phaseChanged(this.selectedItem.phaseCode);
      this.groupChanged(this.selectedItem.groupCode);
    },
    setAdd() {
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1;

      if (this.codePropagated.code !== "ALL") {
        this.selectedItem.phaseCode = this.codePropagated.parentCode;
        this.selectedItem.groupCode = this.codePropagated.code;
      }
      // this.selectedItem.phaseCode = this.codePropagated.code !== 'ALL' ? { phaseCode: this.codePropagated.parentCode, groupCode: "1" } : { }
      this.selected = [];
      this.receivedItem = [];
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.accessUser = [];
      this.accessGroup = [];
      this.subItemOptions = []
      this.modOpen = true;
      this.$refs.form.reset();

      setTimeout(() => {
        this.selectedItem = {
          phaseCode: this.codePropagated.parentCode,
          groupCode:
            this.codePropagated.code !== "ALL" ? this.codePropagated.code : {}
        };
        this.phaseChanged(this.selectedItem.phaseCode);
        this.groupChanged(this.selectedItem.groupCode);
      });
    },
    setEdit(selectedIndex) {
      // myArray.map(x => x.hello).indexOf('stevie')
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign({}, this.wButton[this.selectedIndex]);
      this.selected = [Object.assign({}, this.wButton[this.selectedIndex])];
      this.receivedItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.isOpen) {
        if (
          this.prevSelectedItem.subItem != this.selectedItem.subItem ||
          this.prevSelectedItem.reportName != this.selectedItem.reportName ||
          this.prevSelectedItem.accessUser != this.selectedItem.accessUser ||
          this.prevSelectedItem.accessGroup != this.selectedItem.accessGroup ||
          this.prevSelectedItem.desc != this.selectedItem.desc
        )
          return true;
        return false;
      }
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {};
        this.$refs.form.reset();
        this.accessUser = [];
        this.accessGroup = [];
      } else {
        this.selectedItem = Object.assign({}, this.receivedItem[0]);
      }
    },
    cancel() {
      this.open = false;
    },
    isCodeValid(buttonCode) {
      return !this.wButton.find(item => item.buttonCode == buttonCode);
    }
  }
};
</script>
